const COLORS = {
    PheonixSunsYellow: '#F9AD1B',
    UtahJazzGreen: '#00471B',
    PhoenixSunPurple: '#1D1160',
    BostonCelticsBeige: '#E59E6D',
    HoustonRocketsSilver: '#C4CED4',
    OklahomaCityThunderOrange: '#EF3B24',
    DenverNuggetsRed: '#8B2131',
    IndianaPacersYellow: '#FDBB30',
    SacramentoKingsPurple: '#5A2D81',
};

export default COLORS;