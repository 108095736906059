import {
    ReactComponent as AboutCircleSvg,
} from '../../Assets/Images/about_circle.svg';

type Props = {
    className?: string,
}

const AboutCircle: React.FC<Props> = ({
    className,
}) => {
    return (
        <AboutCircleSvg
            className={className}
        />
    );
}

export default AboutCircle;
