import {
    ReactComponent as InstagramSvg,
} from '../../Assets/Images/instagram.svg';

import {
    ReactComponent as FacebookSvg,
} from '../../Assets/Images/facebook.svg';

import {
    ReactComponent as SpotifySvg,
} from '../../Assets/Images/spotify.svg';

import {
    ReactComponent as SoundcloudSvg,
} from '../../Assets/Images/soundcloud.svg';

import {
    useColorStyles,
} from '../../Hooks';
import { FunctionComponent, SVGProps } from 'react';

export type SocialMediaType = 'facebook' | 'instagram' | 'spotify' | 'soundcloud';

type Props = {
    type: SocialMediaType
    className?: string,
}

const SocialMediaIcon: React.FC<Props> = ({
    type,
    className,
}) => {
    const {
        secondaryTextStyle,
    } = useColorStyles();

    const getComponent = () => {
        switch (type) {
            case 'instagram': return InstagramSvg;
            case 'facebook': return FacebookSvg;
            case 'spotify': return SpotifySvg;
            case 'soundcloud': return SoundcloudSvg;
        }
    }

    const Component: FunctionComponent<SVGProps<SVGSVGElement>> | undefined = getComponent();

    if (!Component) return <></>;

    return (
        <Component
            style={secondaryTextStyle}
            className={className}
        />
    );
}

export default SocialMediaIcon;
