import React, {
    CSSProperties,
} from "react";

import {
	Link as RouterLink,
} from "react-router-dom";

import {
    useColorStyles,
} from '../../Hooks';

type Props = {
    path: string,
    external?: boolean,
    className?: string,
    index?: number,
    children: any,
    onClick?: React.MouseEventHandler<HTMLAnchorElement>,
    style?: CSSProperties,
}

const Link: React.FC<Props> = ({
    path,
    external,
    className,
    index,
    children,
    onClick,
    style,
}) => {
    const {
        secondaryTextStyle,
    } = useColorStyles();

    const renderLocal = () => <RouterLink style={style || secondaryTextStyle} onClick={onClick} className={className} key={index} to={path}>{children}</RouterLink>;
    const renderExternal = () => <a style={style || secondaryTextStyle} onClick={onClick} className={className} key={index} href={path} target='_blank' rel="noreferrer">{children}</a>;

    return external ? renderExternal() : renderLocal();
}

export default Link;
