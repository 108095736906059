import cn from "classnames";
import {
    useLocation,
} from "react-router-dom";

import {
    useNavigation,
    useColorStyles,
} from "../../Hooks";

import {
    Link,
    Logo,
} from "..";

import styles from './MobileNavigation.module.css';
import MobileNavigationButton from "./MobileNavigationButton";

type Props = {
    className?: string,
    visible: boolean,
    setVisible: (value: boolean) => void,
}

const MobileNavigation: React.FC<Props> = ({
    className,
    visible,
    setVisible,
}) => {
    const location = useLocation();

    const {
        secondaryContainerStyle,
        primaryTextStyle,
    } = useColorStyles();

    const {
        navigationLinks,
    } = useNavigation();

    const {
        pathname: currentPathname,
    } = location;

    return (
        <>
            <MobileNavigationButton
                className={styles.MobileNavigationButton}
                onClick={() => setVisible(true)} />
            {visible && <div className={cn(styles.Root, className)}>
                <div
                    className={styles.Navigation}
                    style={secondaryContainerStyle}>
                    {navigationLinks.map((link, index) => (
                        <Link
                            path={link.path}
                            key={index}
                            style={primaryTextStyle}
                            className={cn(styles.Link, link.path === currentPathname && styles.LinkActive)}
                            onClick={() => setVisible(false)}
                            external={link.external}>
                            {link.display}
                        </Link>
                    ))}
                    <div className={styles.Stretcher}></div>
                    <div className={styles.LogoWrapper}>
                        <Logo
                            style={primaryTextStyle}
                            className={styles.Logo} />
                    </div>
                </div>
                <div
                    className={styles.Overlay}
                    onClick={() => setVisible(false)}>
                </div>
            </div>}
        </>
    );
}

export default MobileNavigation;