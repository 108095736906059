import { useMemo } from "react";

import {
    COLORS,
    ROUTES,
} from '../Constants';

import {
	useLocation,
} from "react-router-dom";

const useColors = () => {
    const location = useLocation();
    const { pathname } = location;

    const primaryColor = useMemo(() => {
        switch (pathname) {
            case ROUTES.Main: return COLORS.PheonixSunsYellow;
            case ROUTES.About: return COLORS.PhoenixSunPurple;
            case ROUTES.Contact: return COLORS.DenverNuggetsRed;
        }
    }, [pathname]);

    const secondaryColor = useMemo(() => {
        switch (pathname) {
            case ROUTES.Main: return COLORS.UtahJazzGreen;
            case ROUTES.About: return COLORS.BostonCelticsBeige;
            case ROUTES.Contact: return COLORS.IndianaPacersYellow;
        }
    }, [pathname]);

    return {
        primaryColor,
        secondaryColor,
    };
};

export default useColors;
