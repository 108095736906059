import { useTranslation } from "react-i18next";
import {
    Link,
    SocialMediaIcon,
    Text,
} from "../../Components";

import {
    EXTERNALS,
} from "../../Constants";

import styles from "./ContactPage.module.css";

const ContactPage = () => {
    const {
        t: translate,
    } = useTranslation('main', { keyPrefix: 'pages.contact' });
    
    return (
        <div className={styles.ContactPage}>
            <div className={styles.Content}>
                <div>
                    <Text className={styles.Header} content={translate('header')}/>
                </div>
                <div className={styles.DescriptionWrapper}>
                    <div className={styles.Description}>
                        <Text content={translate('description.firstLine')}/>
                    </div>
                    <div className={styles.Description}>
                        <Text content={translate('description.secondLine')}/>
                    </div>
                </div>
                <div>
                    <div className={styles.FollowUsWrapper}>
                        <Text className={styles.FollowUs} content={translate('followUs')}/>
                    </div>
                    <div className={styles.SocialMediaLinkWrapper}>
                        <Link className={styles.SocialMediaLink} external={true} path={EXTERNALS.facebook}>
                            <div className={styles.SocialMediaLinkSection}>
                                <SocialMediaIcon className={styles.SocialMediaLinkIcon} type="facebook" />
                                <Text className={styles.SocialMediaLinkSectionContent} content={translate('socialMedias.facebook')}/>
                            </div>
                        </Link>
                    </div>
                    <div className={styles.SocialMediaLinkWrapper}>
                        <Link className={styles.SocialMediaLink} external={true} path={EXTERNALS.instagram}>
                            <div className={styles.SocialMediaLinkSection}>
                                <SocialMediaIcon className={styles.SocialMediaLinkIcon} type="instagram" />
                                <Text className={styles.SocialMediaLinkSectionContent} content={translate('socialMedias.instagram')}/>
                            </div>
                        </Link>
                    </div>
                    <div className={styles.SocialMediaLinkWrapper}>
                        <Link className={styles.SocialMediaLink} external={true} path={EXTERNALS.soundcloudRoot}>
                            <div className={styles.SocialMediaLinkSection}>
                                <SocialMediaIcon className={styles.SocialMediaLinkIcon} type="soundcloud" />
                                <Text className={styles.SocialMediaLinkSectionContent} content={translate('socialMedias.soundcloud')}/>
                            </div>
                        </Link>
                    </div>
                </div>
                
            </div>
        
        </div>
    );
}

export default ContactPage;
