import {
    useMemo,
} from "react";

import {
    useTranslation,
} from "react-i18next";

import {
    EXTERNALS,
} from "../../Constants";

import grassmanPhoto from '../../Assets/Images/Crew/grassmann.png';
import djdyingPhoto from '../../Assets/Images/Crew/djdying.png';
import jrziPhoto from '../../Assets/Images/Crew/jrzi.png';
import minettiPhoto from '../../Assets/Images/Crew/minetti.png';
import yoniboyPhoto from '../../Assets/Images/Crew/yoniboy.png';

import { SocialMediaType } from "../../Components/SocialMediaIcon/SocialMediaIcon";

export type CrewMemberUrl = {
    url: string,
    socialMediaType: SocialMediaType,
    description?: any,
}

export type CrewMember = {
    nickname: string,
    photo: string,
    bio: string,
    urls: CrewMemberUrl[],
}

const useAboutPage = () => {
    const {
        t: translate,
    } = useTranslation('main', { keyPrefix: 'pages.about' });

    const crewMembers: CrewMember[] = useMemo(() => ([
        {
            nickname: translate('crew.grassmann.nickname'),
            photo: grassmanPhoto,
            bio: translate('crew.grassmann.bio'),
            urls: [
                { url: EXTERNALS.crew.grassmann.instagram, socialMediaType: 'instagram', description: translate('crew.grassmann.socialMediaHandle.instagram') }
            ]
        },
        {
            nickname: translate('crew.djdying.nickname'),
            photo: djdyingPhoto,
            bio: translate('crew.djdying.bio'),
            urls: [
                { url: EXTERNALS.crew.djdying.instagram, socialMediaType: 'instagram', description: translate('crew.djdying.socialMediaHandle.instagram') },
                { url: EXTERNALS.crew.djdying.facebook, socialMediaType: 'facebook', description: translate('crew.djdying.socialMediaHandle.facebook') },
                { url: EXTERNALS.crew.djdying.spotify, socialMediaType: 'spotify', description: translate('crew.djdying.socialMediaHandle.spotify') },
            ]
        },
        {
            nickname: translate('crew.jrzi.nickname'),
            photo: jrziPhoto,
            bio: translate('crew.jrzi.bio'),
            urls: [
                { url: EXTERNALS.crew.jrzi.instagram, socialMediaType: 'instagram', description: translate('crew.jrzi.socialMediaHandle.instagram') },
                { url: EXTERNALS.crew.jrzi.facebook, socialMediaType: 'facebook', description: translate('crew.jrzi.socialMediaHandle.facebook') },
                { url: EXTERNALS.crew.jrzi.spotify, socialMediaType: 'spotify', description: translate('crew.jrzi.socialMediaHandle.spotify') },
                { url: EXTERNALS.crew.jrzi.soundcloud, socialMediaType: 'soundcloud', description: translate('crew.jrzi.socialMediaHandle.soundcloud') },

            ]
        },
        {
            nickname: translate('crew.minetti.nickname'),
            photo: minettiPhoto,
            bio: translate('crew.minetti.bio'),
            urls: [
                { url: EXTERNALS.crew.minetti.instagram, socialMediaType: 'instagram', description: translate('crew.minetti.socialMediaHandle.instagram') },
            ]
        },
        {
            nickname: translate('crew.djyoniboy.nickname'),
            photo: yoniboyPhoto,
            bio: translate('crew.djyoniboy.bio'),
            urls: [
                { url: EXTERNALS.crew.djyoniboy.instagram, socialMediaType: 'instagram', description: translate('crew.djyoniboy.socialMediaHandle.instagram') },
            ]
        }
    ]), [translate]);

    return {
        crewMembers,
    };
};

export default useAboutPage;
