const EXTERNALS = {
    facebook: 'https://www.facebook.com/ruchy47',
    instagram: 'https://www.instagram.com/rvchy47/',
    bandcamp: 'https://ruchy47.bandcamp.com/music',
    soundcloud: 'https://soundcloud.com/ruchy47/sets/ruchy47-mixxx',
    soundcloudRoot: 'https://soundcloud.com/ruchy47',
    infoEmail: 'info@ruchy47.com',
    crew: {
        grassmann: {
            instagram: 'https://www.instagram.com/grdmsky/',
        },
        djdying: {
            instagram: 'https://www.instagram.com/zbitegary/',
            facebook: 'https://www.facebook.com/djdying',
            spotify: 'https://open.spotify.com/artist/3JDyOlpKuML3t6gehgTq6Q?si=ugAcmv1QQsqkjyzJU7Rm3A',
        },
        jrzi: {
            instagram: 'https://www.instagram.com/jrzi47/',
            facebook: 'https://www.facebook.com/jrzi.official',
            soundcloud: 'https://soundcloud.com/jrzi',
            spotify: 'https://open.spotify.com/artist/5yYCy1e613N8UATKXYZCR1?si=v6vZDFsqRfiAqh79W1uuwg',
        },
        minetti: {
            instagram: 'https://www.instagram.com/dziadstefan/',
        },
        djyoniboy: {
            instagram: 'https://www.instagram.com/jankiboj/',
        },
    },
};

export default EXTERNALS;