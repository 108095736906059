import {
    ReactComponent as ArrowSvg,
} from '../../Assets/Images/arrow.svg';

import {
    useColorStyles,
} from '../../Hooks';

type Props = {
    className?: string,
}

const Arrow: React.FC<Props> = ({
    className,
}) => {
    const {
        secondaryTextStyle,
    } = useColorStyles();

    return (
        <ArrowSvg
            className={className}
            style={secondaryTextStyle}
        />
    );
}

export default Arrow;
