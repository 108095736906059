import cn from "classnames";

import {
    useState,
} from "react";

import {
    useLocation,
} from "react-router-dom";

import {
    useNavigation,
    useColorStyles,
} from '../../Hooks';

import {
    Logo,
    Link,
    MobileNavigation,
} from "..";

import {
    ROUTES,
} from "../../Constants";

import styles from './HeaderBar.module.css';

const HeaderBar = () => {
    const [mobileNavigationVisible, setMobileNavigationVisible] = useState(false);
    const location = useLocation();

    const {
        secondaryTextStyle,
    } = useColorStyles();

    const {
        navigationLinks,
    } = useNavigation();

    const {
        pathname: currentPathname,
    } = location;

    return (
        <div className={styles.HeaderBar}>
            <span style={secondaryTextStyle} className={styles.Logo}>
                <Link path={ROUTES.Main}>
                    <Logo className={styles.Logo} />
                </Link>
            </span>
            <span className={styles.Stretcher}></span>
            <span className={styles.MobileNavigationWrapper}>
                <MobileNavigation
                    visible={mobileNavigationVisible}
                    setVisible={setMobileNavigationVisible}
                />
            </span>
            <span className={styles.DesktopNavigationWrapper}>
                {navigationLinks.map((link, index) => (
                    <Link
                        path={link.path}
                        key={index}
                        className={cn(styles.Link, link.path === currentPathname && styles.LinkActive)}
                        external={link.external}>
                        {link.display}
                    </Link>
                ))}
            </span>
        </div>
    );
}

export default HeaderBar;
