import {
	Routes,
	Route,
} from "react-router-dom";

import WebFont from 'webfontloader';

import {
	HeaderBar,
} from "./Components";

import {
	useColorStyles,
} from "./Hooks";

import {
	ROUTES,
} from "./Constants";

import {
	HomePage,
	AboutPage,
	ContactPage,
} from './Pages';

import styles from './App.module.css';
import FooterBar from "./Components/FooterBar";

WebFont.load({
    custom: {
      families: ['tarrgetital'],
    },
});

const App = () => {
	const { primaryContainerStyle } = useColorStyles();
	return (
		<div style={primaryContainerStyle} className={styles.App}>
			<div className={styles.AppInnerWrapper}>
				<HeaderBar />
				<Routes>
					<Route path={ROUTES.Main} element={<HomePage />} />
					<Route path={ROUTES.About} element={<AboutPage />} />
					<Route path={ROUTES.Contact} element={<ContactPage />} />
				</Routes>
				<FooterBar />
			</div>
		</div>
	)
}

export default App;
