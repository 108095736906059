import {
    useTranslation,
} from "react-i18next";

import {
    Text,
    Logo,
    Link,
    Arrow,
} from "../../Components";

import {
    ROUTES,
} from "../../Constants";

import styles from "./HomePage.module.css";

const HomePage = () => {
    const {
        t: translate,
    } = useTranslation('main', { keyPrefix: 'pages.homepage' });
    
    return (
        <div className={styles.HomePage}>
            <div className={styles.Content}>
                <div className={styles.Row}>
                    <div>
                        <Logo className={styles.Logo} />
                    </div>
                    <div>
                        <div>
                            <Text className={styles.Header} content={translate('header.firstLine')}/>
                        </div>
                        <div>
                            <Text className={styles.Header} content={translate('header.secondLine')}/>
                        </div>
                        <div>
                            <Text className={styles.Header} content={translate('header.thirdLine')}/>
                        </div>
                        <div className={styles.ReadMoreLinkWrapper}>
                            <Link className={styles.ReadMoreLink} path={ROUTES.About}>
                                <div className={styles.ReadMoreSection}>
                                    <Text className={styles.ReadMore} content={translate('readMore')}/>
                                    <Arrow />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
