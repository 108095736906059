import { useMemo } from "react";

import useColors from "./useColors";

const useColorStyles = () => {
    const {
        primaryColor,
        secondaryColor,
    } = useColors();

    const primaryTextStyle = useMemo(() => ({
        color: primaryColor,
    }), [primaryColor]);

    const primaryContainerStyle = useMemo(() => ({
        backgroundColor: primaryColor,
    }), [primaryColor]);

    const secondaryTextStyle = useMemo(() => ({
        color: secondaryColor,
    }), [secondaryColor]);

    const secondaryContainerStyle = useMemo(() => ({
        backgroundColor: secondaryColor,
    }), [secondaryColor]);

    const secondaryBorderStyle = useMemo(() => ({
        borderColor: secondaryColor,
    }), [secondaryColor]);

    return {
        primaryTextStyle,
        primaryContainerStyle,
        secondaryTextStyle,
        secondaryContainerStyle,
        secondaryBorderStyle,
    };
};

export default useColorStyles;
