import {
    MouseEventHandler,
} from 'react';

import {
    ReactComponent as MenuSvg,
} from '../../../Assets/Images/menu.svg';

import {
    useColorStyles,
} from '../../../Hooks';

type Props = {
    onClick?: MouseEventHandler<SVGSVGElement>,
    className?: string,
}

const MobileNavigationButton: React.FC<Props> = ({
    onClick,
    className,
}) => {
    const {
        secondaryTextStyle,
    } = useColorStyles();

    return (
        <MenuSvg
            onClick={onClick}
            className={className}
            style={secondaryTextStyle}
        />
    );
}

export default MobileNavigationButton;