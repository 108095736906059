import { useTranslation } from "react-i18next";
import {
    AboutCircle,
    Arrow,
    Link,
    SocialMediaIcon,
    Text,
} from "../../Components";

import {
    ROUTES,
} from "../../Constants";

import styles from "./AboutPage.module.css";
import useAboutPage, {
    CrewMember,
} from "./useAboutPage";

const AboutPage = () => {
    const {
        t: translate,
    } = useTranslation('main', { keyPrefix: 'pages.about' });
    
    const { 
        crewMembers,
    } = useAboutPage();

    const renderCrewMember = (member: CrewMember, index: number) => (
        <div className={styles.CrewMember} key={index}>
            <div className={styles.CrewMemberHeaderWrapper}>
                <Text className={styles.Header} content={member.nickname}/>
            </div>
            <div className={styles.Row}>
                <div>
                    <img alt={member.nickname} className={styles.CrewMemberPhoto} src={member.photo} />
                </div>
                <div>
                    <div>
                        <Text content={member.bio}/>
                    </div>
                    <div className={styles.CrewMemberUrls}>
                        {member.urls.map((link, urlIndex) => (
                            <div key={urlIndex} className={styles.CrewMemberUrlWrapper}>
                                <Link className={styles.CrewMemberUrl} path={link.url} external={true}>
                                    <SocialMediaIcon className={styles.CrewMemberUrlSocialMediaIcon} type={link.socialMediaType} />
                                    <span>{link.description}</span>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.AboutPage}>
            <div className={styles.Content}>
                <div className={styles.Row}>
                    <div className={styles.AboutRuchy}>
                        <div>
                            <Text className={styles.Header} content={translate('aboutRuchy.header')}/>
                        </div>
                        <div >
                            <Text content={translate('aboutRuchy.description.firstParagraph')}/>
                        </div>
                        <div>
                            <Text content={translate('aboutRuchy.description.secondParagraph')}/>
                        </div>
                        <div>
                            <Link className={styles.ContactUsLink} path={ROUTES.Contact}>
                                <div className={styles.ContactUsSection}>
                                    <Text className={styles.ContactUs} content={translate('aboutRuchy.contactUs')}/>
                                    <Arrow />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.AboutCircleWrapper}>
                        <AboutCircle />
                    </div>
                </div>
                <div>
                    {crewMembers.map((member, index) => renderCrewMember(member, index))}
                </div>
            </div>
        
        </div>
    );
}

export default AboutPage;
