import {
    CSSProperties,
} from 'react';

import {
    ReactComponent as LogoSvg,
} from '../../Assets/Images/logo.svg';

import {
    useColorStyles,
} from '../../Hooks';

type Props = {
    className?: string,
    style?: CSSProperties,
}

const Logo: React.FC<Props> = ({
    className,
    style,
}) => {
    const {
        secondaryTextStyle,
    } = useColorStyles();

    return (
        <LogoSvg
            className={className}
            style={style || secondaryTextStyle}
        />
    );
}

export default Logo;
