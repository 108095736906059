
import {
    useTranslation,
} from 'react-i18next';

import {
    useColorStyles,
} from '../../Hooks';

import {
    Link,
    SocialMediaIcon,
    Text,
} from "..";

import {
    EXTERNALS,
} from '../../Constants';

import styles from './FooterBar.module.css';

const HeaderBar = () => {
    const {
        t: translate,
    } = useTranslation('main', { keyPrefix: 'components.footer' });

    const {
        secondaryBorderStyle,
    } = useColorStyles();

    return (
        <div style={secondaryBorderStyle} className={styles.FooterBar}>
            <div>
                <div>
                    <Text content={translate('copyright')} />
                </div>
                <div>
                    <Link external path={`mailto:${EXTERNALS.infoEmail}`}>
                        <Text content={EXTERNALS.infoEmail} />
                    </Link>
                </div>
            </div>
            <div className={styles.Right}>
                <Link className={styles.SocialMediaLink} external={true} path={EXTERNALS.facebook}>
                    <SocialMediaIcon className={styles.SocialMediaLinkIcon} type="facebook" />
                </Link>
                <Link className={styles.SocialMediaLink} external={true} path={EXTERNALS.instagram}>
                    <SocialMediaIcon className={styles.SocialMediaLinkIcon} type="instagram" />
                </Link>
                <Link className={styles.SocialMediaLink} external={true} path={EXTERNALS.soundcloudRoot}>
                    <SocialMediaIcon className={styles.SocialMediaLinkIcon} type="soundcloud" />
                </Link>
            </div>
            
        </div>
    );
}

export default HeaderBar;
