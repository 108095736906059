import {
    useColorStyles,
} from '../../Hooks';

type Props = {
    content: string,
    className?: string,
}

const Text: React.FC<Props> = ({
    content,
    className,
}) => {
    const {
        secondaryTextStyle,
    } = useColorStyles();

    return (
        <span className={className} style={secondaryTextStyle}>
            {content}
        </span>
    );
}

export default Text;
