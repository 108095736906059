import {
    useMemo,
} from "react";
import {
    useTranslation,
} from "react-i18next";

import {
    ROUTES,
    EXTERNALS,
} from "../Constants";

export type NavigationLink = {
    display: string,
    path: string,
    external?: boolean,
}

const useNavigation = () => {
    const {
        t: translate,
    } = useTranslation('main', { keyPrefix: 'components.header' });
    
    const navigationLinks: NavigationLink[] = useMemo(() => ([
        {
            display: translate("navigation.main"),
            path: ROUTES.Main,
        },
        {
            display: translate("navigation.about"),
            path: ROUTES.About,
        },
        {
            display: translate("navigation.releases"),
            path: EXTERNALS.bandcamp,
            external: true,
        },
        {
            display: translate("navigation.mixes"),
            path: EXTERNALS.soundcloud,
            external: true,
        },
        {
            display: translate("navigation.contact"),
            path: ROUTES.Contact,
        },
    ]), [translate]);

    return {
        navigationLinks,
    };
};

export default useNavigation;
